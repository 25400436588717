import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent
} from '@mui/material';
const axios = require('axios');

const LeadForm: React.FC = () => {

  const { partner } = useParams<{ partner?: string }>();

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    companyName: '',
    quantityEmployees: '1-5',
  });

  const [errors, setErrors] = useState({
    phone: '',
    email: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value.trimStart() });
  };

  const handleQuantityEmployeesChange = (e: SelectChangeEvent<string>) => {
    setFormData({ ...formData, quantityEmployees: e.target.value as string });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validação... (como mencionado no exemplo anterior)
    const { name, phone, email, companyName, quantityEmployees } = formData;
    if (!name || !phone || !email || !companyName || !quantityEmployees) {
      alert('Todos os campos são obrigatórios.');
      return;
    }

    // Validação de e-mail
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailPattern.test(email)) {
      setErrors({ ...errors, email: 'E-mail inválido' });
      return;
    } else {
      setErrors({ ...errors, email: '' });
    }

    // Validação de phone (neste exemplo, verifica apenas se contém dígitos)
    if (!/^\d+$/.test(phone)) {
      setErrors({ ...errors, phone: 'Telefone inválido' });
      return;
    } else {
      setErrors({ ...errors, phone: '' });
    }

    console.log(formData)
    const options = {
      method: 'POST',
      url: 'https://us-central1-beneflex.cloudfunctions.net/createLead',
      headers: {'Content-Type': 'application/json'},
      data: {
        email,
        name,
        phone,
        companyName,
        quantityEmployees,
        partner,
      }
    };

    try {
      await axios.request(options)
      alert('Enviado com sucesso')
    } catch (error) {
      alert('Falhar ao enviar')
      
    }
  };

  return (
    <Container>
      <Typography variant="h4" align="center">
        {/* Formulário */}
        {partner}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} marginTop={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Nome"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Telefone (Somente digitos)"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
              error={!!errors.phone}
              helperText={errors.phone}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              error={!!errors.email}
              helperText={errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Nome da Empresa"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Quantidade de Colaboradores</InputLabel>
              <Select
                name="Quantidade de Colaboradores"
                value={formData.quantityEmployees}
                onChange={handleQuantityEmployeesChange}
                required
              >
                <MenuItem value="1-5">1 a 5</MenuItem>
                <MenuItem value="6-20">6 a 20</MenuItem>
                <MenuItem value="21-50">21 a 50</MenuItem>
                <MenuItem value="51+">Acima de 50</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginTop: '20px' }}
        >
          Enviar
        </Button>
      </form>
    </Container>
  );
};

export default LeadForm;
